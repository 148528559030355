



















































































































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";
import Project from "@/entity/Project";
import { union, differenceWith, isEqual, map, remove } from "lodash";
import Industry, { ContactIndustry } from "@/entity/Industry";

@Component
export default class CompanyFormBusinessInfo extends Mixins<RulesMixin>(
  RulesMixin
) {
  @Prop({ required: true, type: Array }) projects!: Project[];
  @Prop({ required: true, type: Array }) companyTypes!: ReferenceItem[];

  @Prop({ type: Boolean }) isExternal!: boolean;
  @PropSync("contactBranches", { type: Array })
  contactBranchesLocal!: ContactIndustry[];
  @PropSync("subBranchIds", { type: Array }) subBranchIdsLocal!: number[];
  @PropSync("regionId", { type: Number }) regionIdLocal!: number | null;
  @PropSync("buyerTypeId", { type: Number }) buyerTypeIdLocal!: number | null;
  @PropSync("projectReferenceIds", { type: Array })
  projectReferenceIdsLocal!: number[];
  @PropSync("companyTypeId", { type: Number })
  companyTypeIdLocal!: number | null;
  @PropSync("employees", { type: Number })
  employeesLocal!: number | null;
  @PropSync("fullTimeEquivalent", { type: String })
  fullTimeEquivalentLocal!: string | null;

  @State("industries", { namespace: "selectOptions" })
  industries!: Industry[];
  @State("subIndustries", { namespace: "selectOptions" })
  subIndustries!: ReferenceItem[];
  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];
  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];

  onProjectReferenceChange(projects: number[]): void {
    let newContactBranches = this.contactBranchesLocal;

    if (projects.length > this.projectReferenceIdsLocal.length) {
      /**
       * PROJECT WAS ADDED TO REFERENCES
       * - all its branches needs to be added
       * - if this contact already uses the branch with manual: true it should not be overwritten
       */
      const addedProjectId = differenceWith(
        projects,
        this.projectReferenceIdsLocal,
        isEqual
      )[0];
      const projectById = this.projects.find(
        (project) => project.id === addedProjectId
      );

      // Take branches from project and set flag manual to false
      const projectBranches =
        projectById?.projectContact.contactBranches?.map((branch) => ({
          ...branch,
          manual: false,
        })) ?? [];

      projectBranches.forEach((branch) => {
        const isAlreadyAdded =
          newContactBranches.find((b) => b.id === branch.id) !== undefined;

        if (!isAlreadyAdded) {
          newContactBranches.push(branch);
        }
      });
    } else if (projects.length < this.projectReferenceIdsLocal.length) {
      /**
       * PROJECT WAS REMOVED FROM REFERENCES
       * - all its branches needs to be removed but if any of the branches is used from other project
       * or was added manually it should not be removed
       */
      const removedProjectId = differenceWith(
        this.projectReferenceIdsLocal,
        projects,
        isEqual
      )[0];

      const projectById = this.projects.find(
        (project) => project.id === removedProjectId
      );

      // Ids of branches from removed project
      const projectBranchIds = map(
        projectById?.projectContact.contactBranches,
        "id"
      ) as number[];

      // Projects that are still remaining in the project-buyers of references
      // Need to get all the branches from these projects
      const remainingProjects = this.projects.filter((project) =>
        projects.includes(project.id as number)
      );

      const remainingProjectBranches = union(
        ...remainingProjects.map(
          (project) => project.projectContact.contactBranches
        )
      );

      const remainingProjectBranchIds = map(remainingProjectBranches, "id");

      const safeToDelete: number[] = projectBranchIds.filter((branchId) => {
        const isInRemainingProjectBranches =
          remainingProjectBranchIds.includes(branchId);
        const wasManuallyAdded = this.contactBranchesLocal.find(
          (contactBranch) =>
            contactBranch.id === branchId && contactBranch.manual
        );

        return !isInRemainingProjectBranches && wasManuallyAdded === undefined;
      });

      newContactBranches = remove(this.contactBranchesLocal, (industry) => {
        return safeToDelete.includes(industry.id as number);
      });

      newContactBranches = this.contactBranchesLocal.filter(
        (industry) => !safeToDelete.includes(industry.id as number)
      );
    }

    this.projectReferenceIdsLocal = projects;
    this.contactBranchesLocal = newContactBranches;
  }

  onManualBranchChange(value: Industry[]): void {
    const newValue: any[] = value;
    if (newValue.length > this.contactBranchesLocal.length) {
      const addedItem = differenceWith(
        newValue,
        this.contactBranchesLocal,
        isEqual
      )[0];
      const newItem: ContactIndustry = {
        id: addedItem.id as number,
        name: addedItem.name,
        manual: true,
      };
      const indexOfBranch = newValue.findIndex(
        (branch) => newItem.id === branch.id
      );
      if (indexOfBranch !== -1) {
        newValue[indexOfBranch] = newItem;
      }
    }

    this.contactBranchesLocal = newValue;
  }
}
