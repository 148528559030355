































































import { Component, Mixins, PropSync } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";

@Component
export default class CompanyFormOwnerContact extends Mixins(RulesMixin) {
  @PropSync("ownerContactFirstName", { type: String })
  ownerContactFirstNameLocal!: string;
  @PropSync("ownerContactName", { type: String })
  ownerContactNameLocal!: string;
  @PropSync("ownerContactMail", { type: String })
  ownerContactMailLocal!: string;
  @PropSync("ownerContactPhoneMobile", { type: String })
  ownerContactPhoneMobileLocal!: string;
  @PropSync("ownerContactPhoneFixed", { type: String })
  ownerContactPhoneFixedLocal!: string;
}
