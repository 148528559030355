













































import { Component, Mixins, PropSync } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import { countryList } from "@/entity/Contact";

@Component
export default class CompanyFormAddressInfo extends Mixins(RulesMixin) {
  countries = countryList;

  @PropSync("address", { type: String, required: true }) addressLocal!: string;
  @PropSync("postcode", { type: String, required: true })
  postcodeLocal!: string;
  @PropSync("city", { type: String, required: true }) cityLocal!: string;
  @PropSync("countryId", { type: Number }) countryIdLocal!: number | null;
}
