

















































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import Contact from "@/entity/Contact";

@Component
export default class CompanyFormContactInfo extends Mixins(RulesMixin) {
  @Prop({ required: true }) owners!: Contact[];

  @Prop({ type: Boolean, required: true }) isEditing!: boolean;

  @PropSync("name", { type: String, required: true })
  nameLocal!: string;
  @PropSync("ownerContactId", { type: Number }) ownerContactIdLocal!:
    | number
    | null;
  @PropSync("url", { type: String, required: true }) urlLocal!: string;
  @PropSync("mail", { type: String, required: true }) mailLocal!: string;
  @PropSync("phoneMobile", { type: String, required: true })
  phoneMobileLocal!: string;
  @PropSync("phoneFixed", { type: String, required: true })
  phoneFixedLocal!: string;
  @PropSync("isExternal", { type: Boolean, required: true })
  isExternalLocal!: string;

  @PropSync("createNewOwnerContact", { type: Boolean })
  createNewOwnerContactLocal!: boolean;

  get ownerOuterIcon(): string | null {
    if (!this.isExternalLocal) {
      return null;
    }

    if (this.createNewOwnerContactLocal) {
      return "mdi-close";
    }

    return "mdi-plus";
  }

  get ownerContactIdRules() {
    if (this.isExternalLocal && !this.createNewOwnerContactLocal) {
      return [this.rules.required];
    }

    return [];
  }
}
